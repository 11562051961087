import React from "react"
import { Formik, Field, Form, useField } from 'formik'
import * as Yup from 'yup'

import Layout from "../components/layout"
import SEO from "../components/seo"

// Check if window is defined
const isBrowser = typeof window !== "undefined"

const myStorage = isBrowser && window.localStorage;

const apiURL = "https://api.mnemona.sk/api/"
//const apiURL = "http://localhost:8180/api/"

const FormSchema = Yup.object().shape({
  "1": Yup.string().required('Vyberte jednu možnosť!'),
  "2": Yup.string().required('Vyberte jednu možnosť!'),
  "3": Yup.string().required('Vyberte jednu možnosť!'),
  "4": Yup.string().required('Vyberte jednu možnosť!'),
  "5": Yup.string().required('Vyberte jednu možnosť!'),
  "6": Yup.string().required('Vyberte jednu možnosť!'),
  "7": Yup.string().required('Vyplňte toto pole!'),
  "8": Yup.string().required('Vyplňte toto pole!'),
});

const MyTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, index] = useField(props);
  return (
      <>
          <label
            htmlFor={props.id || props.name}
            key={`${index}_text_input`}
            className="select-none container block relative cursor-pointer text-md pl-8"
            >
          <textarea
            className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
           {...field} {...props} />
           </label>
      </>
  );
};

export default class IndexPage extends React.Component {
  state = {
    questions: [],
    answers: [],
    loading: true,
    submit: null,
    data: []
  }

  componentDidMount() {
    this.fetchQuestions();
    const submit = isBrowser ? myStorage.getItem('submit') : false;
    submit && this.setState({submit: submit});
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.state) !== JSON.stringify(nextState);
  }

  fetchQuestions = () => {
    fetch(`${apiURL}questions`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          this.setState({questions: resultData.data});
        })
        .finally(() => this.setState({loading: false}))
        .catch(() => {
          return alert(`Nastala chyba pri načítaní formulára !`)
        })
  }

  render() {

  const questions = this.state.questions;

  return (
    <Layout>
      <SEO title="Napíšte nám" />
      { this.state.submit && !this.state.loading && (
        <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="container mx-auto">
        <div className="items-center justify-center" >
          <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">

            <div className="text-gray-600 text-2xl font-black mt-2 text-center">
              Ďakujeme za Váš čas
            </div>
          </div>
        </div></div></div>
      )}
      { (this.state.submit === null || !this.state.submit) && this.state.loading && (
        <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="container mx-auto">
        <div className="items-center justify-center" >
          <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
            <div className="loader-dots block relative w-20 h-5 mt-2">
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
            </div>
            <div className="text-gray-500 text-xs font-light mt-2 text-center">
              Nahrávam dotazník...
            </div>
          </div>
        </div></div></div>
      )}
    { (this.state.submit === null || !this.state.submit) && !this.state.loading && (
    <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
    <div className="container mx-auto">
        <div className="max-w-2xl mx-auto my-10 bg-white p-5 rounded-md shadow-sm">

            <div className="text-center">
                DOTAZNÍK SPOKOJNOSTI
            </div>
            <div className="m-10">

            <Formik
      initialValues={{
        "1": '',
        "2": '',
        "3": '',
        "4": [],
        "5": '',
        "6": '',
        "7": '',
        "8": '',
      }}
      validationSchema={FormSchema}
      onSubmit={async (values) => {
        //await sleep(500);
        //console.log(values);
        //alert(JSON.stringify(values, null, 2));
        fetch(`${apiURL}questions`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          this.setState({questions: resultData.data});
        })
        .finally(() => this.setState({loading: false}))
        .catch(() => {
          return alert(`Nastala chyba pri načítaní formulára !`)
        })

        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: values })
        };

        fetch(`${apiURL}answers-bulk`, requestOptions)
            .then(response => response.json())
            .then(data => {
              this.setState({ submit: true, data: data.data });
              isBrowser && myStorage.setItem('submit', true);
            })
            .catch(() => {
              this.setState({ submit: false})
              return alert(`Nastala chyba pri odoslaní formulára !`)
            })
        }}
    >
      {({ errors, touched, values }) => (
 <Form>

{
        questions.map((question, index) => {

          let validText = '';

          if (errors[`${question.id}`] && touched[`${question.id}`]) {
            validText = 'text-red-600';
          }

          if (question.options.type === 'radiobutton') {

            return (
              <div key={index} className="my-10">
                <h2 className={`my-3 text-md font-semibold text-gray-700 dark:text-gray-200 ${validText}`}>
                  {question.name}
                  </h2>
                {
                  question.options.options.map((option, id) => {
                     return (
                      <label
                        key={`${index}_option_${id}`}
                        className="select-none container block relative cursor-pointer text-md pl-8">
                          <Field
                            type="radio"
                            name={`${question.id}`}
                            value={option.id}
                            className="form-radio h-5 w-5 text-blue-600"

                          />

                        <span className="ml-2 text-gray-700">
                          {option.text}
                        </span>

                      </label>
                     )
                  })
                }
                 {errors[`${question.id}`] && touched[`${question.id}`] ? (
                          <div className="text-red-700 text-right">{errors[`${question.id}`]}</div>
                          ) : null}
              </div>

          )
          }

          if (question.options.type === 'checkbox') {

            return (
              <div key={index} className="my-10">
                <h2 className={`my-3 text-md font-semibold text-gray-700 dark:text-gray-200 ${validText}`}>
                  {question.name}
                  </h2>
                {
                  question.options.options.map((option, id) => {
                     return (
                      <label
                        key={`${index}_option_${id}`}
                        className="select-none container block relative cursor-pointer text-md pl-8 ml-8 mb-2"
                      >
                        {option.text}
                        <Field type="checkbox" name={`${question.id}`}  value={option.id} className="absolute opacity-0 left-0 top-0 cursor-pointer"  />
                        <span className="h-6 w-6 checkmark absolute top-0 left-0 bg-gray-200"></span>
                      </label>
                     )
                  })
                }
                {errors[`${question.id}`] && touched[`${question.id}`] ? (
                          <div className="text-red-700 text-right">{errors[`${question.id}`]}</div>
                          ) : null}
              </div>
            )
          }

          if (question.options.type === 'text') {

            return (
              <div key={index} className="my-10">
                <h2 className={`my-3 text-md font-semibold text-gray-700 dark:text-gray-200 ${validText}`}>
                  {question.name}
                </h2>

                      <label
                        key={`${index}_text_input`}
                        className="select-none container block relative cursor-pointer text-md"
                        >
                       <Field

                            type="text"
                            name={`${question.id}`}
                            placeholder="Váš preferovaný čas"
                            className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                          />
                          {errors[`${question.id}`] && touched[`${question.id}`] ? (
                          <div className="text-red-700 text-right">{errors[`${question.id}`]}</div>
                  ) : null}
                      </label>

              </div>
            )
          }

          if (question.options.type === 'textarea') {


            return (
              <div key={index} className="my-10">
                <h2 className={`my-3 text-md font-semibold text-gray-700 dark:text-gray-200 ${validText}`}>
                  {question.name}
                  </h2>


                          <MyTextArea
                            index={index}
                            name={`${question.id}`}
                            rows="6"
                            placeholder="Váš názor"
                          />
                          {errors[`${question.id}`] && touched[`${question.id}`] ? (
                            <div className="text-red-700 text-right">{errors[`${question.id}`]}</div>
                  ) : null}

              </div>
            )
          }


          return null
        })
      }
      <div className="mb-6">
        <button type="submit" className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none">
          Odoslať dotazník
        </button>
      </div>
</Form>)}
      </Formik>

    </div>
        </div>
    </div>
</div>
)}
</Layout>
  )
}

}
